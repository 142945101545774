<template>
  <div class="login-container">
    <el-form
        ref="subForm"
        :model="subForm"
        :rules="loginRules"
        class="login-form"
        autocomplete="on"
        label-position="left"
    >
      <div class="title-container">
        <h3 class="title">快速注册</h3>
        <el-link class="registered" type="primary" @click="goLogin"
        >去登录
        </el-link
        >
      </div>

      <el-form-item prop="user_name">
        <span class="svg-container">
          <i class="el-icon-s-custom"/>
        </span>
        <el-input
            ref="user_name"
            v-model="subForm.user_name"
            placeholder="用户名"
            name="user_name"
            type="text"
            autocomplete="off"
            tabindex="1"
        />
      </el-form-item>
      <el-form-item prop="mb">
        <span class="svg-container">
          <i class="el-icon-mobile-phone"/>
        </span>
        <el-input
            ref="mb"
            v-model="subForm.mb"
            placeholder="手机号"
            name="mb"
            type="text"
            autocomplete="off"
            tabindex="2"
        />
      </el-form-item>
      <el-form-item prop="passwd">
          <span class="svg-container">
            <i class="el-icon-lock"/>
          </span>
        <el-input
            :key="passwordType"
            ref="password"
            v-model="subForm.passwd"
            :type="passwordType"
            placeholder="设置密码"
            name="passwd"
            tabindex="3"
            autocomplete="new-password"
            @keyup.native="checkCapslock"
            @blur="capsTooltip = false"
        />
        <span class="show-pwd" @click="showPwd">
            <i
                :class="
                passwordType === 'password'
                  ? 'el-icon-turn-off'
                  : 'el-icon-open'
              "
            />
          </span>
      </el-form-item>
      <el-form-item prop="smscode">
        <span class="svg-container">
          <i class="el-icon-edit"/>
        </span>
        <el-input
            style="width: 67%"
            ref="username"
            v-model="subForm.smscode"
            placeholder="验证码"
            name="smscode"
            type="text"
            autocomplete="off"
            tabindex="4"
        >
        </el-input>
        <el-button size="small"
                   type="primary"
                   icon="el-icon-edit-outline"
                   @click="_getCode"
                   :disabled="!!timer"
        >验证码<span v-if="timer">({{ count }})</span></el-button
        >
      </el-form-item>

      <el-button
          :loading="loading"
          type="primary"
          size="medium"
          icon="el-icon-s-promotion"
          style="width: 100%; margin-bottom: 10px"
          @click.native.prevent="submit"
      >立即注册
      </el-button
      >
      <div class="agreement">
        <el-checkbox v-model="checked">
          我已阅读
          <el-link type="primary">《中国物流网服务协议》</el-link>
          和
          <el-link type="primary">《中国物流网隐私声明》</el-link>
        </el-checkbox>
      </div>
    </el-form>
  </div>
</template>

<script>
import {registered, getCode} from '@/api/user'

export default {
  name: "Login",
  data() {
    const validatePhone = (rule, value, callback) => {
      if (!value) {
        callback(new Error("请填写"));
      } else if (value < 10) {
        callback(new Error("手机号不能少于11位"));
      } else {
        callback();
      }
    }
    const validatePassword = (rule, value, callback) => {
      if (!value) callback(new Error('请填写'))
      else if (value.length < 6) {
        callback(new Error("密码不能少于6位"));
      } else {
        callback();
      }
    };
    return {
      count: 60,
      timer: null,
      checked: false,
      subForm: {
        mb: '',
        role_name: '货主',
        user_name: "",
        passwd: "",
        open_id: '',
      },
      loginRules: {
        user_name: [{required: true, trigger: "blur", message: "请填写"}],
        mb: [{required: true, trigger: "blur", validator: validatePhone}],
        passwd: [
          {required: true, trigger: "blur", validator: validatePassword},
        ],
        smscode: [
          {required: true, trigger: "blur", message: "请填写"}
        ]
      },
      passwordType: "password",
      capsTooltip: false,
      loading: false,
      showDialog: false,
      redirect: undefined,
      otherQuery: {},
    };
  },
  methods: {
    setTime() {
      if (!this.timer) {
        this.count = 60;
        this.show = false;
        this.timer = setInterval(() => {
          if (this.count > 0 && this.count <= 60) {
            this.count--;
          } else {
            this.show = true;
            clearInterval(this.timer);
            this.timer = null;
          }
        }, 1000);
      }
    },
    // 获取验证码
    _getCode() {
      if (this.timer && this.timer > 0) return
      this.$refs.subForm.validateField("mb", errMsg => {
        if (errMsg) return
        let params = {
          "mb": this.subForm.mb,
          "tmscode": "89569",
          "loginsite": "全部",
          "childcomid": "6601",
          "userID": "admin",
          "userName": "admin"
        }
        getCode(params).then(res => {
          this.setTime()
          this.$message({
            message: res.message,
            type: 'success'
          })
          console.log(res)
        })
      })


    },
    goLogin() {
      this.$router.push({
        name: "login",
      });
    },
    checkCapslock(e) {
      const {key} = e;
      this.capsTooltip = key && key.length === 1 && key >= "A" && key <= "Z";
    },
    showPwd() {
      if (this.passwordType === "password") {
        this.passwordType = "";
      } else {
        this.passwordType = "password";
      }
      this.$nextTick(() => {
        this.$refs.password.focus();
      });
    },
    submit() {
      if (!this.checked) {
        this.$message({
          message: '请同意服务协议和隐私声明',
          type: 'error'
        })
        return
      }
      this.$refs.subForm.validate((valid) => {
        if (valid) {
          this.loading = true;
          let params = {
            method: "Modify",
            proc: "USP_ADD_USERINFO_WXAPP_SMSCODECHECK",
            pars: [
              {...this.subForm}
            ],
            tmscode: "89569",
            loginsite: "全部",
            token: ""
          }
          // todo: 在注册之前，需要验证一下验证码
          registered(JSON.stringify(params)).then(res => {
            this.loading = false
            this.$message({
              message: res.msg,
              type: 'success'
            })
          }).catch(err => {
            this.loading = false
            console.log(err)
          })

        } else {
          this.loading = false
          console.log("error submit!!");
          return false;
        }
      });
    },
    getOtherQuery(query) {
      return Object.keys(query).reduce((acc, cur) => {
        if (cur !== "redirect") {
          acc[cur] = query[cur];
        }
        return acc;
      }, {});
    },
  },
};
</script>

<style lang="scss">
/* 修复input 背景不协调 和光标变色 */
/* Detail see https://github.com/PanJiaChen/vue-element-admin/pull/927 */

$bg: #283443;
$light_gray: #fff;
$cursor: #000;

@supports (-webkit-mask: none) and (not (cater-color: $cursor)) {
  .login-container .el-input input {
    color: $cursor;
  }
}

/* reset element-ui css */
.login-container {
  .agreement{

    margin-bottom: 20px;
    .txt{
      //display: flex;
      //align-items: center;
    }
    ::v-deep .el-checkbox{
      margin-right: 5px;
    }
  }
  .el-input {
    display: inline-block;
    height: 47px;
    width: 85%;

    input {
      background: transparent;
      border: 0px;
      -webkit-appearance: none;
      border-radius: 0px;
      padding: 12px 5px 12px 15px;
      color: $bg;
      height: 47px;
      caret-color: $cursor;

      &:-webkit-autofill {
        box-shadow: 0 0 0px 1000px $bg inset !important;
        -webkit-text-fill-color: $cursor !important;
      }
    }
  }

  .el-form-item {
    border: 1px solid rgba(255, 255, 255, 0.1);
    background: rgba(0, 0, 0, 0.1);
    border-radius: 5px;
    color: #454545;
  }
}
</style>

<style lang="scss" scoped>
$bg: #2d3a4b;
$dark_gray: #889aa4;
$light_gray: #eee;

.login-container {
  min-height: calc(100vh - 105px);
  width: 100%;
  background-color: $bg;
  overflow: hidden;
  background: url("../../assets/login/login-bg.png") no-repeat;
  background-size: 100% 100%;

  .login-form {
    position: relative;
    width: 520px;
    max-width: 100%;
    padding: 40px 35px 0;
    margin: 0 auto;
    margin-top: 90px;
    margin-bottom: 50px;
    overflow: hidden;
    background: #fff;
    opacity: 0.8;
    border-radius: 4px;
    animation: flipInY 1s;
  }

  .tips {
    font-size: 14px;
    color: #fff;
    margin-bottom: 10px;

    span {
      &:first-of-type {
        margin-right: 16px;
      }
    }
  }

  .svg-container {
    padding: 6px 5px 6px 15px;
    color: $dark_gray;
    vertical-align: middle;
    width: 30px;
    display: inline-block;
  }

  .title-container {
    position: relative;

    .registered {
      color: #1062f1;
      font-size: 14px;
      position: absolute;
      right: 3px;
      bottom: 8px;
      &:hover{
        animation: jello 1s;
      }
    }

    .title {
      font-size: 26px;
      color: #000;
      margin: 0px auto 40px auto;
      text-align: center;
      font-weight: bold;
    }
  }

  .show-pwd {
    position: absolute;
    right: 10px;
    top: 7px;
    font-size: 16px;
    color: $dark_gray;
    cursor: pointer;
    user-select: none;
  }

  .thirdparty-button {
    position: absolute;
    right: 0;
    bottom: 6px;
  }

  @media only screen and (max-width: 470px) {
    .thirdparty-button {
      display: none;
    }
  }
}
</style>
